<template>
  <div class="cancelResult flexcolumnstart">
    <img
      src="https://sho-static.shulan.com/minipro/doctoragree.png"
      class="accountTipImg"
    />
    <h3>账号注销申请已提交完成</h3>
    <p class="content">
      1-3个工作日内工作人员会与您取得联系。 如有疑问请联系客服：
      <span @click="bindPhone">{{phone}}</span>
    </p>
    <!-- <div class="activeBtn marginTop70" @click="goback">返回首页</div> -->
    <div class="g-safe-area-bottom"></div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isagree: false,
      phone:'400-103-9999'
    }
  },
  methods: {
    goback() {},
     bindPhone(){
      window.location.href='tel://'+this.phone
    },
  },
  created() {},
  beforeCreate() {},
}
</script>
<style lang="scss">
.cancelResult {
  background: #f7f9fc;
  padding: 94px 0 110px;
  .accountTipImg {
    width: 80px;
    height: 80px;
    margin: 0 auto;
  }

  h3 {
    font-size: 40px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #040814;
    margin: 18px 0 110px;
    text-align: center;
  }
  .tip {
    width: 12px;
    height: 12px;
    background: #040814;
    border-radius: 6px;
    margin-left: 94px;
    margin-right: 27px;
  }
  .content {
    padding: 0 60px;
    font-size: 32px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #040814;
    word-break:normal;
    span {
      color: #5771f9;
    }
  }
}
</style>
